import React, { Component } from 'react'

import { Container } from 'react-bootstrap'
import { FaEnvelope, FaInstagram, FaMap, FaMarker, FaPhone } from 'react-icons/fa'

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className='placeholder' style={{
                    height: '50px'
                }}></div>
                <div className='placeholder' style={{ backgroundColor: 'white' }}></div>

                <div style={{paddingTop: 70}}>
                    <p className='text-center'>
                        <span style={{ fontWeight: 'bold' }}>
                            B.R. Daheim UG
                        </span><br />
                        Ehrenfeldgürtel 104<br />
                        50823 Köln <br />
                        Deutschland
                    </p>
                    <p className='pt-2 text-center'>
                        Tel: <a href='tel:+491749077366' className='a-link'>+491749077366</a><br />
                        E-Mail: <a href='mailto:info@daheim-koeln.com' className='a-link'>
                            info@daheim-koeln.com
                        </a>
                    </p>
                </div>

                <div className='pt-5 socialicons'>
                    <ul style={{ padding: 0, margin: 0, display: 'flex', fontSize: 28, justifyContent: 'center', listStyle: 'none' }}>
                        <li>
                            <a href='tel:+491749077366' className='a-link'>
                                <FaPhone />
                            </a>
                        </li>
                        <li>
                            <a href='mailto:info@daheim-koeln.com' className='a-link'>
                                <FaEnvelope />
                            </a>
                        </li>
                        <li>
                            <a href='https://www.instagram.com/daheim.koeln' className='a-link'>
                                <FaInstagram />
                            </a>
                        </li>

                    </ul>
                </div>

                <div className='pt-5'>
                    <p className='text-center'>
                        <a href='/impressum' className='a-link'>Impressum</a><br />
                        <a href='/datenschutz' className='a-link'>Datenschutz</a>
                    </p>
                </div>

                <p className='text-center pt-5'>
                    &copy; Powered by 
                    <a style={{ fontWeight: 'bold', color: 'yellow'}} 
                    href='https://techterra-labs.com' className='a-link'>TechTerraLabs</a>
                </p>
            </footer>
        )
    }
}
