import React, { Component } from 'react'
import { Navbar, Container, Nav, Image } from 'react-bootstrap'

export default class NavbarHeader extends Component {
    render() {
        return (
            <Navbar expand="lg" className="own-navbar">
                <Container>
                    <Navbar.Brand href="/">
                        <Image src='/images/logoWhite.png' style={{
                            height: 96,
                            width: 96
                        }} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link className='navbar-link' href="/">Start</Nav.Link>
                            <Nav.Link className='navbar-link' href="/gallery">Galerie</Nav.Link>
                            <Nav.Link className='navbar-link' href="/kontakt">Kontakt</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}
