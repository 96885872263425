class ContactHelper {
    async handleContactForm(form) {
        var response = await fetch("https://api.daheim-koeln.com/api/v1/website/sendContactForm", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        });
        var data = (await response).json();
        return data.failed;

    }
}
export default new ContactHelper();