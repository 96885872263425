import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from './pages/Home'
import ContactForm from './pages/ContactForm';
import Imprint from './pages/Imprint';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Gallery from './pages/Gallery';

export default class App extends Component {


  render() {
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/kontakt' element={<ContactForm />} />
            <Route path='/gallery' element={<Gallery/>} />

            <Route path='/impressum' element={<Imprint />} />
            <Route path='/datenschutz' element={<PrivacyPolicy/>} />

          </Routes>
        </BrowserRouter>
      </div>
    )
  }
}
