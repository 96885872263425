import React, { Component } from 'react'

import NavbarHeader from '../components/NavbarHeader'
import { Button, Col, Container, Card, Row, Image } from 'react-bootstrap'

import Footer from '../components/Footer'
import { isMobile } from 'react-device-detect';

export default class Home extends Component {
  render() {
    return (
      <div>
        {/* Start */}
        <section id='start'>
          <NavbarHeader />

          <div className='text-container'>
            <span>Herzlich willkommen bei Daheim</span>
            <p className='text-white'>Ihrem Partner für umfassendes Bauprojektmanagement. <br />
              Wir gestalten Visionen und setzen Ihre Bauprojekte erfolgreich um. <br />
              Entdecken Sie, wie wir Qualität, Effizienz und Innovation vereinen, <br />
              um Ihre Vorstellungen Realität werden zu lassen.</p>
          </div>
        </section>

        <section id='aboutus'>
          <div className='statistics'>
            <Container>
              <Row>
                <Col md={4}>
                  <div className='statistics-center'>
                    <h5>Ereichende Kundenanzahl</h5>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='statistics-center'>
                    <h5>Ereichende Kundenanzahl</h5>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='statistics-center'>
                    <h5>Ereichende Kundenanzahl</h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className='after-statistics'>
            <Row style={{ minHeight: '82vh' }} className='d-flex w-100'>
              <Col md={6} className='left-col'>
              </Col>
              <Col md={6} className='pt-3 pb-3'>
                <h3 className='text-center' style={{
                  fontFamily: 'Raleway',
                  fontWeight: 'bold',
                  marginTop: 50
                }}>Über Uns: Unsere Mission, Ihr Erfolg</h3>
                <p className='mb-text-padding' align="center">Willkommen bei Daheim, Ihrem Partner für erfolgreiches Bauprojektmanagement.<br />
                  Unsere Mission ist es, Ihre Visionen in Wirklichkeit umzuwandeln. Mit erfahrenen Profis, kreativen <br />
                  Lösungen und einem klaren Fokus auf Qualität managen wir Projekte jeder Größe und Komplexität.</p>

                <p style={{ marginLeft: 50 }}><span style={{ fontWeight: 'bold' }}>Warum Daheim:</span> <br /><br />
                  Erfahrung & Expertise:<br />
                  Unsere Fachkenntnisse und langjährige Erfahrung gewährleisten einen reibungslosen Ablauf Ihrer
                  Projekte.<br /><br />
                  Kundenorientierung:<br />
                  Ihre Zufriedenheit steht im Mittelpunkt. Langfristige Partnerschaften und übertroffene Erwartungen
                  sind unser Ziel. <br />
                  <br />
                  Innovation & Nachhaltigkeit: <br />
                  Wir integrieren innovative Ansätze und fördern nachhaltige Baupraktiken für eine zukunftsorientierte
                  Entwicklung.</p>

                <p align="center" className='mb-text-padding' >Daheim – Ihr zuverlässiger Partner für erfolgreiche Bauprojekte. Erfahren Sie mehr<br /> darüber,
                  wie wir Ihre Zukunft gestalten können.</p>

              </Col>
            </Row>
          </div>
        </section>

        <section id='services'>
          <Container>
            <h1 style={{ textAlign: 'center', paddingTop: 85 }}>Unsere Leistungen im Überblick</h1>
            <div className='d-flex justify-content-center'>
              <Row>
                <Col style={{
                  padding: 0,
                  margin: 40, width: 'auto', border: '2px solid black'
                }} md={4} className={isMobile ? 'mx-auto' : ''}>
                  <Image style={{
                    height: 296
                  }} src='/images/services-1.jpg' />
                  <h3 className='text-center'>Fliesen-<br />verlegung</h3>
                </Col>
                <Col style={{
                  padding: 0,
                  margin: 40, width: 'auto', border: '2px solid black'
                }} md={4} className={isMobile ? 'mx-auto' : ''}>
                  <Image style={{
                    height: 296
                  }} src='/images/services-2.jpg' />
                  <h3 className='text-center'>Außen- <br /> fassade</h3>
                </Col>
                <Col style={{
                  padding: 0,
                  margin: 40, width: 'auto', border: '2px solid black'
                }} md={4} className={isMobile ? 'mx-auto' : ''}>
                  <Image style={{
                    height: 296
                  }} src='/images/services-3.jpg' />
                  <h3 className='text-center'>Bade-<br /> zimmer</h3>
                </Col>
              </Row>
            </div>
            <div className='d-flex justify-content-center'>
              <Button 
              onClick={e => {
                e.preventDefault();
                window.location.href = '/kontakt';
              }}
              className='learnMore'>
                Erfahre Mehr
              </Button>
            </div>
          </Container>
        </section>

        <Footer />
      </div>
    )
  }
}
