import React, { Component } from 'react'

import NavbarHeader from '../components/NavbarHeader'
import Footer from '../components/Footer'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'

import ContactHelper from '../services/ContactHelper'

export default class ContactForm extends Component {
    render() {
        return (
            <div>
                <section id='contact'>
                    <NavbarHeader />
                    <Container>
                        <Row aria-autocomplete='off'>
                            <Col md={6} className='pt-2 pb-2'>
                                <Form id='cf' autoComplete='off' method='POST' onSubmit={async (e) => {
                                    e.preventDefault();
                                    var { male, firstname, lastname, email, tel, message } = e.target;
                                    var confirm = window.confirm("Willst du den Kontaktformular absenden?");
                                    if(confirm) {
                                        await ContactHelper.handleContactForm({
                                            male: male.value,
                                            firstname: firstname.value,
                                            lastname: lastname.value,
                                            email: email.value,
                                            tel: tel.value,
                                            message: message.value
                                        });
                                        alert("Vielen Dank für ihre Nachricht!");
                                        
                                        document.getElementById('cf').reset();
                                    }
                                }}>
                                    <Row>
                                        <Col md={12} className='pt-2 pb-2'>
                                            <Form.Label className='text-white'>Anrede</Form.Label>
                                            <Form.Select name='male' className='input' required>
                                                <option value={''} disabled>-</option>
                                                <option value={'w'}>Frau</option>
                                                <option value={'m'}>Herr</option>
                                                <option value={'d'}>Divers</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {/* Firstname And lastname */}
                                    <Row>
                                        <Col md={6} className='pt-2 pb-2'>
                                            <Form.Group>
                                                <Form.Label className='text-white'>Vorname</Form.Label>
                                                <Form.Control required placeholder='Vorname' name='firstname' className='input' type='text' />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className='pt-2 pb-2'>
                                            <Form.Group>
                                                <Form.Label className='text-white'>Nachname</Form.Label>
                                                <Form.Control
                                                    required
                                                    placeholder='Nachname'
                                                    name='lastname'
                                                    className='input' type='text' />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className='pt-2 pb-2'>
                                            <Form.Label className='text-white'>
                                                E-Mail
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                name='email'
                                                type='email'
                                                placeholder='E-Mail'
                                                className='input' />
                                        </Col>
                                        <Col md={6} className='pt-2 pb-2'>
                                            <Form.Label className='text-white'>
                                                Telefon
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                name='tel'
                                                type='tel'
                                                placeholder='Telefon'
                                                className='input' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Label className='text-white'>
                                                Ihre Nachricht
                                            </Form.Label>
                                            <Form.Control
                                                name='message'
                                                required
                                                type='text' as='textarea'
                                                className='input' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className='pt-2 pb-2'>
                                            <Form.Check
                                                style={{ color: 'white' }}
                                                name='check1'
                                                required
                                                label='Ich erlaube der Daheim UG, meine persönlichen Daten für die Kontaktaufnahme und Beratung zu nutzen. Die Einwilligung kann jederzeit widerrufen werden, und Datenschutzinformationen sind in ihrer Datenschutzerklärung verfügbar.' />
                                            <Form.Check
                                                name='check2'
                                                required
                                                style={{ color: 'white' }}
                                                label='Ich bin über die Allgemeinen Geschäftsbedingungen der Daheim UG informiert und stimme diesen zu.'
                                            />
                                            <Form.Check
                                                name='newsletter'
                                                label='Ja, ich möchte Neuigkeiten, Updates und Benachrichtigungen über die Services der Daheim UG per E-Mail erhalten'
                                                style={{ color: 'white' }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Button className='bg-success mt-2' type='submit'>
                                                Absenden
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col md={6} className='pt-2 pb-2'>
                                <p className='bussiness-details'>
                                    <span style={{ fontWeight: 'bold' }}>
                                        B.R. Daheim UG
                                    </span><br />
                                    Ehrenfeldgürtel 104<br />
                                    50823 Köln <br />
                                    Deutschland<br />
                                    <br />
                                    Tel: <a href='tel:+491749077366' className='a-link'>
                                        +49 174 907 73 66</a><br />
                                    E-Mail: <a href='mailto:info@daheim-koeln.com' className='a-link'>
                                        info@daheim-koeln.com
                                    </a>

                                    <br /><br />
                                    <span style={{ fontWeight: 'bold' }}>
                                        Öffnungszeiten
                                    </span>
                                    <br />
                                    Mo. - Sa.: 13.00 - 18.00 Uhr<br />
                                    Soontag und Feiertage: Geschlossen

                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <Footer />
            </div>
        )
    }
}
