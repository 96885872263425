import React, { Component } from 'react'

import "slick-carousel/slick/slick.css";

import NavbarHeader from '../components/NavbarHeader'
import Footer from '../components/Footer'

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";

import { Col, Container, Image, Row } from 'react-bootstrap';

import { TbHeartHandshake } from "react-icons/tb";
import { FaProjectDiagram } from "react-icons/fa";
import { AiOutlinePicture } from "react-icons/ai";


export default class Gallery extends Component {

  getSize() {
    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return windowWidth;
  }

  render() {
    var settings = {
      dots: true,
      autoplay: true,
      autoplaySpeed: 3000,
      useCSS: true,
      slidesToShow: 3,
      arrows: false,
      className: 'slick-slider',
      responsive: [{
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }]
    };
    return (
      <div>
        <section id='gallery'>
          <NavbarHeader />

          <h1 className='header-text'>Galerie</h1>
        </section>
        <section id='gallery_page2'>
          <Container>
            <h1 className='text-center'
              style={{ fontFamily: 'Abril' }}>Projektportfolio:<br /> Unsere Erfolgsgeschichten</h1>
            <p className='text-center description'>Erkunden Sie unser Projektportfolio, in dem wir stolz eine Vielzahl abgeschlossener Projekte präsentieren. In unserer Galerie finden Sie nicht nur beeindruckende Bilder, sondern auch detaillierte Beschreibungen, die Einblicke in die Herausforderungen, Lösungen und die letztendlichen Ergebnisse jedes Projekts gewähren.</p>

            <Row className='pt-5 pb-5'>
              <Col md={4} className='pt-2 pb-2'>
                <div className='d-flex justify-content-center'>
                  <FaProjectDiagram style={{
                    fontSize: 72
                  }} />
                </div>
                <h3 style={{ textAlign: 'center', fontFamily: 'Abril' }}>Projektdetails</h3>
                <p className='text-center'>
                  Erfahren Sie mehr über die Herausforderungen, denen wir begegnet sind, die innovativen Lösungen, die wir entwickelt haben, und die konkreten Ergebnisse, die aus unserer engagierten Arbeit hervorgegangen sind.
                </p>
              </Col>
              <Col md={4} className='pt-2 pb-2'>
                <div className='d-flex justify-content-center'>
                  <AiOutlinePicture style={{
                    fontSize: 72
                  }} />
                </div>
                <h3 style={{ textAlign: 'center', fontFamily: 'Abril' }}>Bildgalerie</h3>
                <p className='text-center'>Tauchen Sie ein in visuelle Impressionen unserer abgeschlossenen Bauprojekte. Von kleinen Renovierungen bis hin zu großen Bauvorhaben – jedes Bild erzählt eine Geschichte von Erfolg und Präzision.</p>
              </Col>
              <Col md={4} className='pt-2 pb-2'>
                <div className='d-flex justify-content-center'>
                  <TbHeartHandshake style={{
                    fontSize: 72
                  }} />
                </div>
                <h3 style={{ textAlign: 'center', fontFamily: 'Abril' }}>Kundenreferenzen</h3>
                <p className='text-center'>
                  Lesen Sie, was unsere Kunden über die Zusammenarbeit mit Daheim UG zu sagen haben. Kundenzufriedenheit ist unser oberstes Ziel, und die Rückmeldungen unserer Kunden spiegeln unser Engagement für Exzellenz wider.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section id='gallery_photos'>
          <Container>
            {this.getSize() > 1024 ? (
              <>
                <div className='three_row'>
                  <Image src='/images/gallery/Post-1.jpg' className='image' />
                  <Image src='/images/gallery/Post-2.jpg' className='image' />
                  <Image src='/images/gallery/Post-3.jpg' className='image' />
                </div>
                <div className='three_row'>
                  <Image src='/images/gallery/Post-4.jpg' className='image' />
                  <div className='placeholder-image'></div>
                  <div className='placeholder-image'></div>
                </div>
              </>
            ) : null}

            {this.getSize() < 1024 ? (
              <>

                <Slider {...settings}>
                  <div>
                    <center>
                      <div className='three_row'>
                        <Image src='/images/gallery/Post-1.jpg' className='image' />
                        <Image src='/images/gallery/Post-2.jpg' className='image' />
                      </div>
                    </center>
                  </div>
                  <div>
                    <center>
                      <div className='three_row'>
                        <Image src='/images/gallery/Post-3.jpg' className='image' />
                        <Image src='/images/gallery/Post-4.jpg' className='image' />
                      </div>
                    </center>
                  </div>                </Slider>
              </>
            ) : null}
          </Container>
        </section>
        <Footer />
      </div>
    )
  }
}
